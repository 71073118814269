/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title, ldJson, keywords = "" }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description;
  const metaKeywords = keywords || '';
  const defaultTitle = site.siteMetadata?.title;
  const defaultLdJson = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": defaultTitle,
    "url": "https://liderancaegestao.online/",
    "description": "No canal Liderança e Gestão Online você encontrará dicas e resumos sobre os melhores livros de desenvolvimento pessoal e profissional, liderança, gestão de pessoas, empreendedorismo e muito mais.",
    "image": ""
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >

      {ldJson?.map(i => {
        return (
          <script type="application/ld+json">
            {JSON.stringify(i)}
          </script>
        );
      })}
      <script type="application/ld+json">
        {JSON.stringify(defaultLdJson)}
      </script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `pt-BR`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
