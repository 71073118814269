/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children, coverImageUrl = "" }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <CoverImage url={coverImageUrl} />
      <div
        style={{
          // margin: `0 auto`,
          maxWidth: 1280,
          padding: `0 1.0875rem 1.45rem`,
        }}
        className="mt-6 ml-auto mr-auto mb-0"
      >
        <main>{children}</main>
        
        <div className="pb-16"></div>
        <blockquote><small>O site Liderança e Gestão Online é um participante de alguns programas de afiliados e recebe compensação financeira para indicar produtos vinculados a esses programas, sem nenhuma alteração de preço para você.</small></blockquote>
      </div>
      <footer className="theme-color text-white">
        <div className="px-6 pt-6 text-center">
          <div className="flex justify-center mb-6">
            <a target="_blank" rel="noreferrer" href="http://www.youtube.com/channel/UCvzIf-3dR5sQMdR7mLxLuYA?sub_confirmation=1" type="button" className="rounded-full border-2 border-white text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1">
              <svg aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="youtube"
                className="w-3 h-full mx-auto"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
                ></path>
              </svg>
            </a>

            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/liderancaegestao_online/" type="button" className="rounded-full border-2 border-white text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1">
              <svg aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="instagram"
                className="w-3 h-full mx-auto"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                ></path>
              </svg>
            </a>
          </div>
        </div>

        <section className="text-white-600 body-font">
          <div className="container flex flex-wrap px-5 py-8 mx-auto items-center">
            <div className="md:w-1/2 md:pr-12 md:py-8 md:border-r md:border-b-0 mb-10 md:mb-0 pb-10 border-b border-gray-200">
              <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-white-900">Liderança e Gestão Online</h1>
              {/* <p className="leading-relaxed text-base">Reviews e Insights sobre os principais livros, Best-sellers, sobre liderança e gestão.</p> */}
              <p className="leading-relaxed text-base">No canal Liderança e Gestão Online você encontrará dicas e resumos sobre os melhores livros de desenvolvimento pessoal e profissional, liderança, gestão de pessoas, empreendedorismo e muito mais.</p>
              <p className="leading-relaxed text-base">
                Por meio da indicação de livros escritos por grandes nomes da literatura nacional e internacional, conheça as principais referências com foco em liderança e gestão para o seu desenvolvimento pessoal e profissional.
              </p>
              <Link className="text-indigo-500 inline-flex items-center mt-4" to="/sobre">Saiba mais
                <svg fill="none" stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
            <div className="flex flex-col md:w-1/2 md:pl-12">
              <h2 className="title-font font-semibold text-white-800 tracking-wider text-sm mb-3">Categorias</h2>
              <nav className="flex flex-wrap list-none -mb-1">
                <li className="lg:w-1/3 mb-1 w-1/2">
                  <Link className="text-white-600 hover:text-indigo-800" to="/blog">blog</Link>
                </li>
                <li className="lg:w-1/3 mb-1 w-1/2">
                  <Link className="text-white-600 hover:text-indigo-800" to="/lideranca-e-gestao/livros-de-ceos/">Livros de CEOs</Link>
                </li>
                <li className="lg:w-1/3 mb-1 w-1/2">
                  <Link className="text-white-600 hover:text-indigo-800" to="/lideranca-e-gestao/lideres-extraordinarios/">Lideres Extraordinários</Link>
                </li>
                <li className="lg:w-1/3 mb-1 w-1/2">
                  <Link className="text-white-600 hover:text-indigo-800" to="/lideranca-e-gestao/livros-de-ceos/a-regra-e-nao-ter-regras-a-netflix-e-a-cultura-da-reinvencao">A Regra é Não Ter Regras</Link>
                </li>
                <li className="lg:w-1/3 mb-1 w-1/2">
                  <Link className="text-white-600 hover:text-indigo-800" to="/lideranca-e-gestao/motivacional/">Liderança motivacional</Link>
                </li>
                <li className="lg:w-1/3 mb-1 w-1/2">
                  <Link className="text-white-600 hover:text-indigo-800" to="/">Todos os Livros</Link>
                </li>
              </nav>
            </div>
          </div>

        </section>

        <div className="text-center p-4" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
          © 2022 Copyright {" "}
          <a className="text-whitehite" href="https://wesolveit.com.br/">We Solve It</a>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const CoverImage = ({ url }) => {
  if (!url)
    return null;

  return (<div className="bg-cover bg-center" style={{ width: "100%", height: "400px", backgroundImage: "url(" + url + ")" }}></div>);
}

export default Layout
